import { useIsAuthenticated } from "@azure/msal-react";
import { PageProps } from "gatsby";
import { useEffect } from "react";

import { SEO } from "../components/SEO";

export default function IndexPage({ navigate }: PageProps): JSX.Element {
	const isAuth = useIsAuthenticated();
	useEffect(() => {
		(() => {
			if (isAuth) void navigate("/smlouvy/");
			else {
				void navigate("/rozcestnik/");
			}
		})();
	}, [isAuth]);

	return <>Loading...</>;
}

export const Head = (): JSX.Element => (
	<SEO title="Klientský portál">
		<meta name="robots" content="noindex" />
	</SEO>
);
